module.exports = {
    resumeType: "dynamic", // dynamic or local
    contact: {
        firstname: "Tai",
        lastname: "Helsel",
        email: "tai.helsel@gmail.com",
        github: "github.com/taihelsel/", //do not include https://www
        website: "taihelsel.com",
        location: "Jupiter, FL",
        emailType: "local", //local or sever
    },
    about: "I enjoy utilizing JavaScript to build engaging full stack applications. Most recently, I've been connecting IOT devices with Node.js servers to solve real world problems.",
    skills: [
        "JavaScript",
        "HTML",
        "CSS",
        "React",
        "AdobeXD",
        "Git",
        "Linux",
        "React Native",
        "Express.js",
        "Ruby",
        "MapBox",
        "SQL",
        "MongoDB",
        "AWS",
        "Next.js",
        "Node.js"
    ],
    resumeExperience: [
        {
            company: "Seven Cells",
            title: "Sr. Software Developer",
            date: "June 2022 - Present",
            desc: [
                "Developed, managed, and deployed microservices using Node.js & EC2 instances on AWS",
                `Optimized page load times of ecommerce sites by reducing component re-renders,
                optimizing images for device sizes, improving caching, and more. This led to page load
                times being reduced by up to 80% and directly increased conversion rates by 200%.`,
                "Managed the onboarding and training of new developers",
                "Designed and developed responsive user interfaces for the products web and mobile applications.",
                "Collaborated with support teams to ensure the successful resolution of customer issues.",
                "Developed and maintained the frontend of two ecommerce stores using React.js",
            ],
            skills: "Git, AWS, Next.js, HTML, CSS, JavaScript, React, Node.js, and Express.js",
        },
        {
            company: "Sentry Data Systems",
            title: "Production Support Analyst",
            date: "June 2019 - Nov 2021",
            desc: [
                "Developed tools that reduced time spent on daily tasks by 50%",
                "Identified and resolved errors in data ingestion feeds",
                "Managed and escalated incidents in order to troubleshoot and resolve high priority issues",
                "Developed and integrated Rundeck jobs with PagerDuty to created automated 24/7 alerting",
            ],
            skills: "Git, HTML, CSS, JavaScript, Powershell, Confluence & Jira integration, and Ruby",
        },
        {
            company: "RTRT.me",
            title: "Full Stack Developer",
            date: "May 2018 - Nov 2018 ",
            desc: [
                "Built applications that interfaced with the RTRT.me API",
                "Developed RESTful APIs using Node.js",
                "Translated designs into functional and responsive UI's",
                "Converted old JQuery code base to ES5 standard",
                "Optimized JavaScript functions by using the DRY principles",
            ],
            skills: "Node.js, JavaScript, HTML, CSS, Git, MongoDB, MapBox, AdobeXD, and HighCharts",
        },
        {
            company: "Freelance",
            title: "Web Developer",
            date: "Jan 2018 - May 2018",
            desc: [
                "Translated Adobe XD mockups into functioning and responsive applications",
                "Designed client facing applications using Adobe XD",
                "Interfaced React projects with REST APIs",
                "Improved SEO performance of existing websites by optimizing code base to improve load times",
                "Developed full stack applications using Node.js and React.js",
            ],
            skills: "Node.js, JavaScript, HTML, CSS, Git, AdobeXD, and React.js",
        },
    ],
    additionalExperience: [],
    resumeProjects: [],
    additionalProjects: [
        {
            name: "Node Ardiuno Controller (NAC)",
            desc: "A web application that uses Node.js and React.js to interface with an Arduino board. The Arduino board is connected to an IR Blaster that sends signals to an AC unit.",
            tech: "React, Node.js, Express.js,JavaScript, HTML, CSS, Git, Arduino, Raspberry Pi, and Linux Server ",
            github: "https://github.com/taihelsel/NodeArduinoController",
            link: "",
            img: "https://raw.githubusercontent.com/taihelsel/NodeArduinoController/main/previewImgs/ac-control-1.PNG",
        },
        {
            name: "SoundClout",
            desc: "This project was built to track data changes on songs posted from SoundCloud. The project scrapes data from SoundCloud and then uses React.js to display the data in a graph.",
            tech: "React, Node.js, Express.js, HTML, CSS, Git, and JavaScript",
            github: "https://github.com/taihelsel/soundclout",
            link: "",
            img: "https://camo.githubusercontent.com/cf316c0a018cb91973d46b421cb7364ae3b17d676fd98bbd4dd567fb1c689eb2/68747470733a2f2f692e696d6775722e636f6d2f686836495365392e706e67",
        },
    ],

}